<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Reportes</h4>
            <div class="small text-muted">Reportes de ventas</div>
          </b-col>                        
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="12">
          <b-card>
            <b-row >
              <b-col sm="10">            
                <b-row>            
                  <b-col sm="12" class="pb-1">
                    <b-row>         
                      <b-col md="3" class="p-1 pb-1">
                        <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                      </b-col>
                      <b-col md="3" class="p-1 pb-1">
                        <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                      </b-col> 
                      <b-col sm="3" class="p-1 pb-1">
                        <v-select :options="arr.filters.status_imputation" v-model="filters.status_imputation" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
                      </b-col>                    
                    </b-row>
                  </b-col>         
                  <b-col sm="12">
                    <b-row>
                      <b-col sm="3" class="p-1">
                        <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
                      </b-col>                       
                      <b-col sm="3" class="p-1">
                        <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
                      </b-col>                        
                      <b-col sm="3" v-if="moduleSellersActive" class="p-1">
                        <v-select :options="arr.filters.sellers" v-model="filters.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
                      </b-col>                                    
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="2" class="p-1">
                <b-row>
                  <b-col sm="12">
                    <b-button variant="outline-dark" @click="filterTab()" size="sm" class="pull-right" :disabled="table.preload || pivot.preload">
                      <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                      Filtrar
                    </b-button>
                  </b-col>                       
                </b-row>
              </b-col>
            </b-row>   
          </b-card>
        </b-col>
      </b-row>
      
      <b-tabs class="mb-3">          
        <b-tab title="IVA Ventas" @click="setTabActive('iva')" :disabled="pivot.preload">
          
          <b-col sm="12" class="mr-0 pr-0" v-if="table.items.length && !table.preload">
            <b-dropdown right text="Exportar" size="sm" variant="outline-warning" class="pull-right mt-2 mb-2">
              <b-dropdown-item>
                <export-excel
                    :data = "arr.export"
                    worksheet = "IVA Ventas"
                    name = "taxes-sales.xls">
                    Exportar Datos
                </export-excel>
              </b-dropdown-item>
              <b-dropdown-item @click="exportFormatIvaDigital()">
                Libro IVA Venta Digital
              </b-dropdown-item>
            </b-dropdown>            
          </b-col>

          <b-table-simple hover 
                          small 
                          caption-top 
                          fixed 
                          v-if="table.items.length && !table.preload && !table.init">

            <b-thead head-variant="dark">                    
              <b-tr>
                <b-th width="10%" class="text-left">Empresa</b-th>                
                <b-th width="15%" class="text-center">Comprobante</b-th>
                <b-th width="10%" class="text-center">Fecha</b-th>
                <b-th width="10%" class="text-center">Vto</b-th>
                <b-th width="15%" class="text-left">Cliente</b-th>
                <b-th width="10%" class="text-right">Neto</b-th>
                <b-th width="10%" class="text-right" v-for="(itemI, indexI) in arr.columns.iva_conditions" :key="indexI">{{itemI.label}}</b-th>
                <b-th width="10%" class="text-right">Total</b-th>
              </b-tr>
            </b-thead> 
            <b-tbody>
              <b-tr v-for="item in table.items" :key="item.id">

                <b-td class="text-left align-middle">
                  {{item.business.reference}}
                </b-td>

                <b-td class="text-center align-middle">
                  <div v-if="item.type_voucher && item.point_sale" 
                        :class="{'text-danger font-weight-bold':(item.afip_error && !item.point_sale.nd), 'text-success font-weight-bold':item.afip && item.afip_qr && !item.point_sale.nd}" 
                        :title="item.afip_error">                           
                    {{item.type_voucher.name}} 
                    {{item.point_sale.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')}}
                  </div>
                </b-td>

                <b-td class="text-center align-middle">
                  {{moment(item.date).format('DD/MM/YYYY')}}
                </b-td>

                <b-td class="text-center align-middle">
                  {{moment(item.date_expiration).format('DD/MM/YYYY')}}                    
                </b-td>
                
                <b-td>
                  <div v-if="item.customer">
                    <span style="font-size: 9px">{{item.customer.data_fiscal.documents_number}} / {{item.customer.data_fiscal.fiscal_condition.name}}</span>
                    <br>
                    <b>{{item.customer.name}}</b>
                  </div>                    
                </b-td>
                
                <b-td class="text-right align-middle">                  
                  <div v-if="item.currency">
                    <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                      <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_net)}})</b>
                    </div>
                    <div style="color:green;" v-else>
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_net)}}</b>
                    </div>                        
                  </div>                    
                </b-td>
                
                <b-td class="text-right align-middle" v-for="(itemI, indexI) in arr.columns.iva_conditions" :key="indexI">
                  <div v-if="item.currency">
                    <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                      <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item['amount_iva_' + itemI.label])}})</b>
                    </div>
                    <div style="color:green;" v-else>
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item['amount_iva_' + itemI.label])}}</b>
                    </div>
                  </div>
                </b-td>
                
                <b-td class="text-right align-middle">                    
                  <div v-if="item.currency">
                    <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                      <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}})</b>
                    </div>
                    <div style="color:green;" v-else>
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}}</b>
                    </div>                        
                  </div>                                        
                </b-td>
              </b-tr>

              <b-tr>
                <b-td class="text-right align-middle badge-dark" colspan="5"></b-td>

                <b-td class="text-right align-middle badge-light" width="10%">
                  <b style="font-size:15px;">{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(totals.net)}}</b>
                </b-td>

                <b-td class="text-right badge-light" width="10%" v-for="(itemI, indexI) in arr.columns.iva_conditions" :key="indexI">
                  <b style="font-size:15px;" v-if="(itemI.total_iva > 0)">{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(itemI.total_iva)}}</b>
                  <b style="font-size:15px;" v-else>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(itemI.total_net)}}</b>
                </b-td>

                <b-td class="text-right align-middle badge-light" width="10%">
                  <b style="font-size:15px;">{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(totals.total)}}</b>
                </b-td>
              </b-tr>              
            </b-tbody>  

          </b-table-simple>  
          
          <div class="text-center" v-if="table.preload && !table.init">
            <b-spinner label="Spinning"></b-spinner>
          </div>
          
          <b-alert v-if="!table.items.length && !table.preload && !table.init" variant="warning" show>No se encontraron registros</b-alert>
          <b-alert v-if="!table.items.length && table.init" variant="info" show>Configure los filtros para obtener datos</b-alert>
        </b-tab>        
        <b-tab title="Ventas" @click="setTabActive('report')" :disabled="table.preload">
          <Pivot :dataJson="pivot.data" v-if="pivot.data && !pivot.preload && !pivot.init"/> 

          <div class="text-center" v-if="pivot.preload && !pivot.init">
            <b-spinner label="Spinning"></b-spinner>
          </div>          

          <b-alert v-if="pivot.init" variant="info" show>Configure los filtros para obtener datos</b-alert>
        </b-tab> 
      </b-tabs>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
        </b-row>
      </b-card>
    
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import Param from '@/config/parameters'
  import moment from 'moment'  
  import Pivot from '@/components/inc/pivot/index'

  export default {     
    components: {
      Pivot,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'reports',
          elements: {}
        },  
        table : {
          items: [],          
          filter: null,
          preload: false,
          init: true,
        }, 
        pivot: {
          data: null,
          preload: false,
          init: true,
        },
        arr: {
          filters : {
            customers: [],
            sellers: [],
            points_sales: [],
            status_imputation: [
              {code: true, label: 'Cancelado'},
              {code: false, label: 'Pendiente'}
            ]
          },   
          columns : {
            iva_conditions: [],
          },
          sales: [],                    
        },
        filters: {
          date_start: '',
          date_end: '',
          customers: null,
          sellers: null,
          points_sales: null,
          status_imputation: null,
          paginate: false,
        },  
        totals: {
          net: 0,
          total: 0,
        },
        tabs: {
          iva: true,
          report: false,
        }
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoad()
    },
    computed: {
      // MODULES
      moduleSellersActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENDEDORES) {                             
            status = true                       
          }
        })        
        return status
      },
      // CONFIGURACION      
      getCurrency() {
        return 'ARS'
      },
    },    
    methods: {
      filterLoad() {
        this.filterLoadCustomers()          
        this.filterLoadSellers()   
        this.filterLoadPointSale()   
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
        this.getIvaConditions()
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          data.forEach(element => {         
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }                             
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.sellers = []
          data.forEach(element => {                            
            this.arr.filters.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.full_identification })            
          });          
        })   
      },   
      getIvaConditions() {
        var result = serviceAPI.obtenerCondicionesIva()
        result.then((response) => {          
          var data = response.data    

          data.forEach(element => {            
            this.arr.columns.iva_conditions.push({ code: element.id, label: element.name, total_net: 0, total_iva: 0 })
          });
          
        })
      },
      filterTab() {
        if(this.tabs.iva){
          this.loadByFilter()    
        } else {
          this.loadByFilterReport()    
        }
      },
      setTabActive(type) {
        if(type == 'iva') {
          this.tabs.iva = true
          this.tabs.report = false
        }
        if(type == 'report') {
          this.tabs.iva = false
          this.tabs.report = true
        }        
      },
      loadByFilter() {        
        this.table.init = false
        this.table.preload = true

        this.tabs.iva = true
        this.tabs.report = false

        this.totals.net = 0
        this.totals.total = 0

        this.arr.columns.iva_conditions.forEach(elementIva => {
          elementIva.total_net = 0,
          elementIva.total_iva = 0
        });

        var result = serviceAPI.filtrarIvaSales(this.filters)

        result.then((response) => {          
          var data = response.data
          this.arr.sales = data
          this.table.items = data

          data.forEach(element => {
            element['amount_net'] = 0

            this.arr.columns.iva_conditions.forEach(elementIva => {
              element['amount_iva_' + elementIva.label] = 0              

              element.details.forEach(elementDet => {
                if(elementDet.iva_conditions_id == elementIva.code){
                  if(elementDet.amount_iva > 0){
                    
                    element['amount_iva_' + elementIva.label] = element['amount_iva_' + elementIva.label] + parseFloat(elementDet.amount_iva)
                    element['amount_net'] = element['amount_net'] + parseFloat(elementDet.amount_net)
                                        
                    if(element.type_voucher.type_balance == '-'){  
                      elementIva.total_iva = elementIva.total_iva - (parseFloat(elementDet.amount_iva) - ((parseFloat(elementDet.amount_iva) * element.percentage_discount) / 100))
                    } else {
                      elementIva.total_iva = elementIva.total_iva + (parseFloat(elementDet.amount_iva) - ((parseFloat(elementDet.amount_iva) * element.percentage_discount) / 100))
                    }

                    if(element.type_voucher.type_balance == '-'){
                      this.totals.net = this.totals.net - (parseFloat(elementDet.amount_net) - ((parseFloat(elementDet.amount_net) * element.percentage_discount) / 100))
                    } else {
                      this.totals.net = this.totals.net + (parseFloat(elementDet.amount_net) - ((parseFloat(elementDet.amount_net) * element.percentage_discount) / 100))
                    }
                  } else {
                    element['amount_iva_' + elementIva.label] = element['amount_iva_' + elementIva.label] + parseFloat(elementDet.amount_net)

                    if(element.type_voucher.type_balance == '-'){
                      elementIva.total_net = elementIva.total_net - (parseFloat(elementDet.amount_net) - ((parseFloat(elementDet.amount_net) * element.percentage_discount) / 100))
                    } else {
                      elementIva.total_net = elementIva.total_net + (parseFloat(elementDet.amount_net) - ((parseFloat(elementDet.amount_net) * element.percentage_discount) / 100))
                    }
                  }
                }
              });

              element['amount_iva_' + elementIva.label] = element['amount_iva_' + elementIva.label] - ((element['amount_iva_' + elementIva.label] * element.percentage_discount) / 100)
            });
            
            element['amount_net'] = element['amount_net'] - ((element['amount_net'] * element.percentage_discount) / 100)         

            if(element.type_voucher.type_balance == '-'){
              this.totals.total = this.totals.total - parseFloat(element.amount_total)
            } else {
              this.totals.total = this.totals.total + parseFloat(element.amount_total)
            }            
          });

          this.table.preload = false
          this.prepareExport(data)
        })
        .catch(error => {          
          this.table.preload = false
          this.$awn.alert(Error.showError(error))
        });
      },   
      loadByFilterReport() {
        this.pivot.init = false
        this.pivot.preload = true
        
        this.tabs.iva = false
        this.tabs.report = true

        var result = serviceAPI.filtrarReportSales(this.filters)

        result.then((response) => {          
          var data = response.data
        
          this.pivot.data = this.processPivot(data)

          this.pivot.preload = false
        })
        .catch(error => {          
          this.pivot.preload = false
          this.$awn.alert(Error.showError(error))
        });
      },
      prepareExport(data) {        
        this.arr.export = []

        data.forEach(element => {
          var businessID = 0
          var businessName = ""          
          var customersName = ""
          var customersCUIT = ""
          var customersCondFiscal = ""          
          var voucherReference = ""

          if(element.business && element.business.reference) {
            businessID = element.business.id            
            businessName = element.business.reference
          }
          if(element.customer) {            
            customersName = element.customer.name
            customersCUIT = element.customer.data_fiscal.documents_number
            customersCondFiscal = element.customer.data_fiscal.fiscal_condition.name
          }          
          if(element.type_voucher) {            
            voucherReference = element.type_voucher.name + ' ' + element.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.number.toString().padStart(8,'0')
          }
          
          var arrExport = []
          arrExport["business"] = businessName,
          arrExport["voucherReference"] = voucherReference,      
          arrExport["date"] = element.date,
          arrExport["date_expiration"] = element.date_expiration,    
          arrExport["customersName"] = customersName,            
          arrExport["customersCUIT"] = customersCUIT,
          arrExport["customersCondFiscal"] = customersCondFiscal,

          arrExport['amount_net'] = parseFloat(element.amount_net).toFixed(2).replace('.',','),

          this.arr.columns.iva_conditions.forEach(elementIva => {
            arrExport['amount_iva_' + elementIva.label] = parseFloat(element['amount_iva_' + elementIva.label]).toFixed(2).replace('.',',')
          });

          arrExport["amount_total"] = parseFloat(element.amount_total).toFixed(2).replace('.',','),

          this.arr.export.push(arrExport)
        });
      },

      processPivot(data) {
        var addData = []        
        data.forEach(element => {
          addData.push({
            date: element.date,
            staff: (element.staffId) ? element.staffName : '',
            seller: (element.sellerId) ? element.sellerName: 'Sin vendedor',
            type_voucher: (element.typeVoucherName) ? element.typeVoucherName : '',
            point_sale: (element.pointSaleId) ? element.pointSaleId : '',
            customer: (element.customerId) ? element.customerName : '',
            number: element.number,            
            amount_total: element.total,
          })
        });

        return {
          dataSource: {                  
            data: addData,
          },
          slice: {
            rows: [
              {
                uniqueName: "seller",
                sort: "asc",
              },                 
              {
                uniqueName: "staff",
                sort: "asc",
              },              
              {
                uniqueName: "customer",
                sort: "asc",
              },                            
            ],
            columns: [
              {
                uniqueName: "date.Month",
                sort: "asc",
              },                  
            ],
            measures: [
              {
                uniqueName: "amount_total",
                aggregation: "sum"
              },                                   
            ],
            expands: {
              expandAll: false,
              rows: [],
              columns: []
            },
            drills: {
              drillAll: false
            }
          },
          options: {
            grid: {
              type: "compact",
              title: "",
              showFilter: true,
              showHeaders: true,
              showTotals: false,
              showGrandTotals: "off",
              showHierarchies: true,
              showHierarchyCaptions: true,
              showReportFiltersArea: true
            },
            configuratorActive: false,
            configuratorButton: true,
            showAggregations: true,
            showCalculatedValuesButton: true,
            drillThrough: true,
            showDrillThroughConfigurator: true,
            sorting: "on",
            datePattern: "dd/MM/yyyy",
            dateTimePattern: "dd/MM/yyyy HH:mm:ss",
            saveAllFormats: false,
            showDefaultSlice: true,
            defaultHierarchySortName: "asc"
          },
          formats: [
            {
              name: "",
              thousandsSeparator: " ",
              decimalSeparator: ".",
              decimalPlaces: 2,
              maxSymbols: 20,
              currencySymbol: "",
              currencySymbolAlign: "left",
              nullValue: " ",
              infinityValue: "Infinity",
              divideByZeroValue: "Infinity"
            }
          ],
          localization: "https://cdn.webdatarocks.com/loc/es.json"                     
        }    
      }, 
      
      // IVA DIGITAL
      exportFormatIvaDigital() {
        let header = ''        
        let details = ''  
        this.arr.sales.forEach(element => {          

          // cambio de cliente en el proceso de facturacion (cambio de venta global a un cliente identificado)
          let type_document_code_afip = element.customer.data_fiscal.type_document.code_afip
          let documents_number = element.customer.data_fiscal.documents_number
          let business_name = element.customer.business_name
          if(element.customer_identification) {
            let customer_identification = JSON.parse(element.customer_identification)

            if(customer_identification.documents_number && customer_identification.name) {
              type_document_code_afip = 96
              documents_number = customer_identification.documents_number
              business_name = customer_identification.name
            }
          }         
          
          // cantidad de alicuotas
          let cantAlicuotas = 0
          if(parseFloat(element["amount_iva_0%"])) {
            cantAlicuotas++
          }
          if(parseFloat(element["amount_iva_10,50%"])) {
            cantAlicuotas++
          }
          if(parseFloat(element["amount_iva_21%"])) {
            cantAlicuotas++
          }
          if(parseFloat(element["amount_iva_27%"])) {
            cantAlicuotas++
          }                                                                   

					header += moment(element.date).format('YYYY').toString().padStart(4,'0') + moment(element.date).format('MM').toString().padStart(2,'0') + moment(element.date).format('DD').toString().padStart(2,'0');
          header += element.type_voucher.code_afip.toString().padStart(3,'0');
          header += element.point_sale.point_sale.toString().padStart(5,'0');
          header += element.number.toString().padStart(20,'0');
          header += element.number.toString().padStart(20,'0');
          header += type_document_code_afip.toString().padStart(2,'0');
          header += documents_number.toString().padStart(20,'0');          
          header += business_name.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().slice(0,30).padEnd(30,' ');          
          header += this.formatNumbersForExport(element.amount_total);
          header += this.formatNumbersForExport(element["amount_iva_No Gravado"]);
          header += this.formatNumbersForExport(0);
          header += this.formatNumbersForExport(element["amount_iva_Exento"]);
          header += this.formatNumbersForExport(0);
          header += this.formatNumbersForExport(0);
          header += this.formatNumbersForExport(0);
          header += this.formatNumbersForExport(0);
          header += "PES";          
          header += "0001000000";          
          header += cantAlicuotas.toString().padStart(1,'0');
          header += "0";
          header += this.formatNumbersForExport(0);
          header += moment(element.date_expiration).format('YYYY').toString().padStart(4,'0') + moment(element.date_expiration).format('MM').toString().padStart(2,'0') + moment(element.date_expiration).format('DD').toString().padStart(2,'0');
          header += '\r\n';


          // obtiene total de los detalles de alicuotas  
          let arr = []
          element.details.forEach(iva => {          
            if(!arr[iva.iva_conditions_id]) {
              arr[iva.iva_conditions_id] = []
              arr[iva.iva_conditions_id]["amount_iva"] = 0
              arr[iva.iva_conditions_id]["amount_net"] = 0
            }

            arr[iva.iva_conditions_id]["amount_iva"] = arr[iva.iva_conditions_id]["amount_iva"] + parseFloat(iva.amount_iva)
            arr[iva.iva_conditions_id]["amount_net"] = arr[iva.iva_conditions_id]["amount_net"] + parseFloat(iva.amount_net)
          });       

          arr.forEach((item, key) => {            
            details += element.type_voucher.code_afip.toString().padStart(3,'0');
            details += element.point_sale.point_sale.toString().padStart(5,'0');
            details += element.number.toString().padStart(20,'0');
            details += this.formatNumbersForExport(this.round(item.amount_net));
            details += key.toString().padStart(4,'0');
            details += this.formatNumbersForExport(this.round(item.amount_iva));                             
            details += '\r\n';
          })          
        });        

        this.downloadTXT("iva_venta_cabecera.txt", header);                    
        this.downloadTXT("iva_venta_alicuotas.txt", details);                    
      },
      round(number) {        
        return Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100;        
      },
      formatNumbersForExport(value) {
        let number = value.toString().split('.')[0].padStart(13,'0')
        if(value.toString().split('.')[1]) {
          number += value.toString().split('.')[1].padEnd(2,'0')
        } else {
          number += '00'
        }
        return number
      },      
      downloadTXT(nameFile, content) {        
        const blob = new Blob([content], { type: "text/plain" });

        const enlace = document.createElement("a");
        enlace.href = URL.createObjectURL(blob);
        enlace.download = nameFile;

        enlace.style.display = "none";
        document.body.appendChild(enlace);
        enlace.click();

        document.body.removeChild(enlace);
        URL.revokeObjectURL(enlace.href);
      }
    }    
  }
</script>